import moment from "moment";

const checkDuration = "00:30";

const adjustTimeToWorkingHours = (time, schedule) => {
  const dayOfWeek = time.day();
  const workingHours = schedule[dayOfWeek];
  const duration = checkDuration.split(":").map((value) => parseInt(value, 10));

  let openAt = workingHours[0].split(":").map((value) => parseInt(value, 10));
  let closeAt = workingHours[1].split(":").map((value) => parseInt(value, 10));

  openAt = time.clone().set({ hour: openAt[0], minute: openAt[1] });
  closeAt = time
    .clone()
    .set({ hour: closeAt[0], minute: closeAt[1] })
    .subtract({ hour: duration[0], minute: duration[1] });

  // If before openingЭто
  if (time < openAt) {
    time = openAt;
  }

  // If closed today, go for tomorrow
  if (time > closeAt) {
    time.add(1, "days");
    const tomorrowWorkingHours = schedule[time.day()];
    let tomorrowOpenAt = tomorrowWorkingHours[0]
      .split(":")
      .map((value) => parseInt(value, 10));
    tomorrowOpenAt = time
      .clone()
      .set({ hour: tomorrowOpenAt[0], minute: tomorrowOpenAt[1] });
    time = tomorrowOpenAt;
  }

  return time;
};

const getAllPossibleDates = (time, schedule) => {
  const dayOfWeek = time.day();
  const workingHours = schedule[dayOfWeek];
  const duration = checkDuration.split(":").map((value) => parseInt(value, 10));

  let openAt = workingHours[0].split(":").map((value) => parseInt(value, 10));
  let closeAt = workingHours[1].split(":").map((value) => parseInt(value, 10));

  openAt = time.clone().set({ hour: openAt[0], minute: openAt[1] });
  closeAt = time
    .clone()
    .set({ hour: closeAt[0], minute: closeAt[1] })
    .subtract({ hour: duration[0], minute: duration[1] });

  const options = [openAt.clone()];

  const step = duration[1];

  let isDone = false;

  while (!isDone) {
    const newDate = openAt.add(step, "minute");
    options.push(newDate.clone());
    if (newDate >= closeAt) isDone = true;
  }

  return options;
};

const adjustTimeToPossibleDates = (time, schedule) => {
  const dates = getAllPossibleDates(time, schedule);
  return dates.find((date) => date.isSameOrAfter(time));
};

const adjustTimeToAvailableDates = (time, taken, schedule) => {
  while (true) {
    // Possible dates this day
    const dates = getAllPossibleDates(time, schedule);

    const freeDates = dates.filter((date) => {
      return !taken.find((takenDate) => moment(takenDate).isSame(date));
    });

    const firstFreeDate = freeDates.find((date) => date.isSameOrAfter(time));

    if (firstFreeDate) {
      time = firstFreeDate;
      break;
    }

    // If we didn't find suitable time this day, move to the next one
    time.add(1, "days");
    const tomorrowWorkingHours = schedule[time.day()];
    let tomorrowOpenAt = tomorrowWorkingHours[0]
      .split(":")
      .map((value) => parseInt(value, 10));
    tomorrowOpenAt = time
      .clone()
      .set({ hour: tomorrowOpenAt[0], minute: tomorrowOpenAt[1] });
    time = tomorrowOpenAt;
  }

  return time;
};

const getNextFreeTime = (current, taken,  schedule) => {
  if (!current) current = moment();

  current.set({second: 0, millisecond: 0})

  current = adjustTimeToWorkingHours(current, schedule);
  current = adjustTimeToPossibleDates(current, schedule);
  current = adjustTimeToAvailableDates(current, taken, schedule);

  return current;
};

const checkIsValidDate = (current, taken) => {

  //if (current < moment().endOf("hour")) return false;

  return !taken
      .map(date => moment(date))
      .find(date => date.isSame(current, 'minute'));

}

export { getNextFreeTime, checkIsValidDate };
