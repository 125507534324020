import React from "react";
import styles from "./styles.module.scss";
import Container from "../ui/container/Container";
import logo from "./logo.png";
import { useSelector, shallowEqual } from "react-redux";

const Footer = () => {
  const { requisites } = useSelector((store) => store.app, shallowEqual);
  const year = new Date().getFullYear();

  return (
    <>
      <div className={styles.footer}>
        <Container className={styles.container}>
          <div className={styles.logo}>
            <img src={logo} alt="" />
            <div>
              <div className={styles.logoName}>{requisites.shortName}</div>
              <div className={styles.subLogo}>Группа компаний</div>
            </div>
          </div>

          <div className={styles.column}>
            <h3>Реквизиты</h3>

            <div>Название: {requisites.name}</div>
            <div>ОРГН: {requisites.ogrn}</div>
            <div>ИНН: {requisites.inn}</div>
          </div>

          <div className={styles.column}>
            <h3>КОНТАКТЫ</h3>
            <div>{requisites.email}</div>
            <div className={styles.phone}>{requisites.phone}</div>
            <div>С пн. по вс. 9:00-21:00</div>
          </div>

          <div className={styles.column}>
            <div><b>Оператор технического осмотра:</b><br/>{requisites.addressOperator}</div>
            {requisites.addressStation?.length > 0 && (
                <div><b>Пункт технического осмотра:</b><br/>{requisites.addressStation}</div>
            )}
          </div>
        </Container>
      </div>

      <div className={styles.copyright}>{year} © Все права защищены</div>
    </>
  );
};

export default Footer;
