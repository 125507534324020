export const types = {
  UPDATE: 'APP/UPDATE',
};

const initialState = {
  schedule: [],
  duration: "00:30",
  takenDates: [],
  requisites: {
    certificate: '',
    name: '',
    ogrn: '',
    inn: '',
    addressOperator: '',
    addressStation: '',
    email: '',
    phone: '',
    shortName: ''
  },
  prices: {
    A: null,
    B: null,
    C: null,
    D: null,
    O: null,
    secondCheck: null,
  },
  additionalPrice: '',
  documents: {
    rules: '',
    typicalAgreement: '',
    techLaw: '',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const actions = {
  update: (payload) => async (dispatch) => {
    dispatch({
      type: types.UPDATE,
      payload,
    });
  },
};
